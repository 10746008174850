import React from 'react';
import { Link } from 'react-router-dom';
import { MainLinks } from './links';

import footerLogoA from '~/src/static/svg/footer-logo-a.svg';
import footerLogoPlane from '~/src/static/svg/logo-plane.svg';
import ebaaLogo from '~/src/static/images/ebaa-logo.png';

function getYear() {
  const year = new Date().getFullYear();
  return year;
}

export const Footer = (props) => (
  <footer>
    <div className="top row flex-align-center justify-center">
      <a href="tel:+43427244766">+43 4272 447 66</a>
      <div className="triangle-fix" />
      <div className="footer-triangle">
        <img className="triangle" src={footerLogoA} alt="logo" />
        <img className="plane" src={footerLogoPlane} alt="logo" />
      </div>
      <a href="mailto:office@avionmar.com">office@avionmar.com</a>
    </div>
    <div className="bottom row flex-align-center justify-center">
      <div className="col-12 row flex-align-center justify-center">
        <Link to="/imprint">
          <div className="imprint-footer">Imprint</div>
        </Link>
        <img className="ebaa-logo" src={ebaaLogo} alt="ebaa-logo" />
        <Link to="/privacy-policy">
          <div className="imprint-footer">Privacy Policy</div>
        </Link>
      </div>
      <MainLinks className="col-12 row flex-align-center justify-center" />
      <p className="copyright uppercase">
        © copyright {getYear()} by avionmar - all rights reserved
      </p>
    </div>
  </footer>
);
