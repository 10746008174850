import React, { Component } from 'react';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, Footer } from '../components/main';
import { Container, Heading } from '../components/layout';

class Imprint extends Component {
  render() {
    return (
      <MainWrapper>
        <Head title="Imprint | AVIONMAR - General Terms of Use for the Avionmar website" />
        <Nav />

        <Container
          className="row flex-align-start justify-center financing"
          section="more-padding"
        >
          <div className="col-7">
            <Heading className="primary">IMPRINT</Heading>
            <div className="col-12">
              <p>
                <b>Office:</b> Hegelgasse 8/14, 1010 Wien
                <br />
                <b>Phone:</b>{' '}
                <a className="link" href="tel:+43427244766 ">
                  +43 4272 44 7 66{' '}
                </a>
                <br />
                <b>Email:</b>{' '}
                <a className="link" href="mailto:office@avionmar.com">
                  office@avionmar.com
                </a>
                <br />
                <b>Web:</b>{' '}
                <a className="link" href="http://www.avionmar.com">
                  www.avionmar.com
                </a>
              </p>
            </div>
            <div className="col-12">
              <h6>photo credits</h6>
              <p>
                © Shutterstock - www.shutterstock.com
                <br />© AVIONMAR - www.avionmar.com
                <br />© Dallas Jet - www.dallasjet.com
                <br />© Wikipedia - www.wikipedia.com
                <br />© Gulfstream - www.gulfstream.com
                <br />© Bombardier - www.bombardier.com
                <br />© Airbus - www.airbus.com
                <br />© Dassault - www.dassault-aviation.com
                <br />© Cessna - www.cessna.txtav.com
                <br />© Boeing Business Jets - www.boeing.com
                <br />© Leonardo Helicopters - www.leonardocompany.com
              </p>
            </div>

            <div className="col-12">
              <h6>General Terms of Use for the Avionmar website</h6>
            </div>

            <div className="col-12">
              <h6>
                By downloading this website and / or your files, the user agrees to
                the following terms of use:
              </h6>
              <ul className="list-ordered">
                <li>
                  <b>Intellectual property rights</b>
                  <p>
                    The entire content of this website, all texts, images, graphics
                    and logos as well as other files made available for download by
                    this server are protected by copyright and / or trademark law.
                  </p>
                </li>
                <li>
                  <b>Permitted uses</b>
                  <p>
                    The use of this website and downloads is only permitted for
                    private non-commercial use. Any other or more extensive use,
                    such as for commercial purposes as well as a duplication or
                    distribution requires the prior written approval of the company
                    AVIONMAR.
                  </p>
                </li>
                <li>
                  <b>Disclaimer</b>
                  <p>
                    AVIONMAR endeavors to publish only complete and correct
                    information on these pages, but no liability or guarantee can be
                    assumed for the topicality, correctness and completeness of the
                    information. This site is subject to constant changes and
                    additions, which serve to update and enrich the information and
                    functions offered. AVIONMAR is not liable for any direct or
                    indirect damages, including lost profits, that may be associated
                    with the use of the information and features offered on this
                    site. This also applies, in particular, to the proven links
                    (links) to other sites over which AVIONMAR has no influence and
                    for whose content AVIONMAR bears no responsibility.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </Container>
        <Footer />
      </MainWrapper>
    );
  }
}
export default Imprint;
